.App {
  text-align: center;
  background: linear-gradient(45deg, #0b0b3b, #3430ac);
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  padding: 20px;
}

.y2k-title {
  font-family: "Orbitron", sans-serif;
  color: #00ffea;
  text-shadow: 0 0 20px #00ffea;
  margin: 0;
}

.y2k-text {
  font-family: "Chakra Petch", sans-serif;
  color: #f6ff00;
  text-shadow: 0 0 10px #f6ff00;
  margin: 20px 0;
}

.y2k-button {
  background-color: #ff009e;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.y2k-button:hover {
  background-color: #cf00b2;
}
